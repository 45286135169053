import React from 'react';
import {ReferenceField, TextField, ReferenceInput, AutocompleteInput, Filter} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const types = [
    { name: "light", cname: "light", value: "light" },
    { name: "contribution", cname: "contribution", value: "contribution" },
    { name: "limpieza", cname: "limpieza", value: "limpieza" },
    { name: "water", cname: "water", value: "water" },
    { name: "work", cname: "work", value: "work" },
    { name: "garden", cname: "garden", value: "garden" },
    { name: "home", cname: "home", value: "home" },
    { name: "insurance", cname: "insurance", value: "insurance" },
    { name: "trash", cname: "trash", value: "trash" },
];
//there is an option alwaysOn for ReferenceInput
export const ExpensesFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Property" source="property.id" reference="properties" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="description" />
        <AutocompleteInput
            source="type"
            choices={types}
            optionText="cname"
            optionValue="value"
        />
    </Filter>
);

export const ExpensesList = props => (
    <div>
        <ListGuesser {...props} filters={<ExpensesFilter/>}>
            <FieldGuesser source="description" />
            <FieldGuesser source="type" />
            <ReferenceField label="Property" source="property" reference="properties">
                <TextField source="name"/>
            </ReferenceField>
            <FieldGuesser source="date" />
            <FieldGuesser source="amount" />
        </ListGuesser>
    </div>

);

export const ExpensesCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="description" />
        <AutocompleteInput
            source="type"
            choices={types}
            optionText="cname"
            optionValue="value"
        />
        <ReferenceInput
            source="property"
            reference="properties"
            label="Property"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="date" />
        <InputGuesser source="amount" />
    </CreateGuesser>
);

export const ExpensesEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="description" />
        <AutocompleteInput
            source="type"
            choices={types}
            optionText="cname"
            optionValue="value"
        />
        <ReferenceInput
            source="property"
            reference="properties"
            label="Property"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="date" />
        <InputGuesser source="amount" />

    </EditGuesser>
);
